import * as React from "react"
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import "./styles.css";
import styled from 'styled-components';
import Hero from '../images/construction.jpg';
import { Helmet } from "react-helmet";
import PartnerLogos from '../components/PartnerLogos';
import Testimonials from '../components/Testimonials';

const Header = styled.div`
`;

const HeroImg = styled.img`
  height: 500px;
  width: 100%;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    height: 300px;
  }
`;

const TitleBox = styled.div`
    background: #e0e0e0;
    position: relative;
    top: -100px;
    max-width: 500px;
    height: 200px;
    opacity: 90%;

    @media screen and (max-width: 600px) {
      max-width: 75%;
      height: 150px;
    }
`;

const Heading = styled.h1`
  padding:60px 40px 40px 0;
  font-size:50px;
  text-align: center;
  font-family: 'Vollkorn', serif;
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size:40px;
  }
`;

const TextSection = styled.div`
font-family: Poppins, sans-serif;
    max-width: 800px;
    margin: -100px auto 0px;
    padding: 50px 20px;
}
`;

const LearnMore = styled.button`
display: block;
margin: 0 auto;
margin-top: 40px;
border: 3px solid rgb(17, 37, 68);
border-radius: 6px;
padding: 20px 50px;
background: rgb(89, 129, 144);
font-size: 18px;
font-weight: bold;

a{
    color: white;
    text-decoration: none;
}

&:hover{
    background: rgb(172, 193, 174);
    cursor: pointer;
}
}
`;

const About = () => {
  return (
    <main>
      <Helmet>
      <title>About</title>
      <meta name="description" content="Dunning Currie Insurance Inc. is an independently owned Canadian Property &amp; Casualty premier insurance brokerage. " />
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <meta charSet="utf-8" />
      </Helmet>
      <Navigation />
      <Header>
         <HeroImg src={Hero} alt="hero image" />
          <TitleBox><Heading>About</Heading></TitleBox>
      </Header>
    <TextSection>
        <p>Dunning Currie Insurance Inc. is an independently owned Canadian Property & Casualty premier insurance brokerage.  With commitment and dedication at the forefront, we spend the time getting to know our clients and their needs so that we can provide:</p>
        <ol>
            <li>Tailored Solutions </li>
            <li>A reduction to our clients risk &amp; exposure</li>
            <li>Thorough Advice</li>
            <li>Competitive rates</li>
        </ol>
        <p>We have fantastic partner relationships and stay current in the evolving industry to ensure our clients get the best coverage and service.</p>
        <p>By combining excellent relationships with our insurer partners, continuing to stay in tune with the ever-evolving insurance industry, we are able to ensure our clients are getting the best coverage, advice and price possible.</p>
        <LearnMore><a href="/products">View our products</a></LearnMore>
    </TextSection>
    <PartnerLogos />
    <Testimonials />
      <Footer />
    </main>
  )
}

export default About

export const Head = () => <title>About</title>
