import * as React from "react"
import styled from 'styled-components';
import Intact from '../images/INSURANCEIntact logo_MASTER_RGB.png';
import Aviva from '../images/Aviva.png';
import Unica from '../images/Unica_rgb.png';
import Berkley from '../images/BUC_2017_Logo_Final.png';
import Bim from '../images/BIM_logo_.jpg';
import MK_R_GG from '../images/MK_R_GG.png';
import GA from '../images/GA.jpg';
import Premier from '../images/Premier_logo_with_Tagline.jpg';
import Chubb from '../images/CHUBB_Logo_DarkBlue_RGB.png';
import Coalition from '../images/Coalition-black-Logo.png';
import Evolution from '../images/logo_evolution_anglais_small.png';
import Trinity from '../images/Trinity-Logo -Vertical.png';
import Cansure from '../images/Cansure_Logo_2020.png';
import Abex from '../images/ABEX-logo-2013.jpg';
import Forward from '../images/Forward-Logo-Fuchsia-Final.png';
import Eco from '../images/1920x1080-eco-centre-blue.jpg';

const Section = styled.div`
clear: both;
 width:100%;
 padding: 60px 20px 80px 20px;

 max-width: 1000px;
 margin: 0 auto;
 border-top: 5px solid #112544;

 @media screen and (max-width: 600px) {
    margin: 20px;
    max-width: 300px;
    margin: 0 auto;
 }
`;

const Container = styled.div`
 height: auto;
 width: auto;
 display: flex;
 justify-content: space-around;
 padding: 0 20px 20px 20px;
 align-items: center;

 @media screen and (max-width: 600px) {
    width: 100%;
    display: block;
 }
`;

const Logos = styled.img`
    display: inline-flex;
    padding: 20px;
    max-width: 150px;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 600px) {
        display: block;
        margin: 0 auto;
    }
    
`;

const LargeLogo = styled(Logos)`
    max-width: 80px;
`;

const Heading = styled.h2`
font-weight: bold;
  color: #112544;
  font-size: 32px;
  font-family: 'Poppins', sans-serif;
`;


const PartnerLogos = () => {
  return (
      <Section>
          <Heading>Who we proudly work&nbsp;with</Heading>
    <Container>
        <Logos src={Aviva} alt="Aviva logo" />
        <Logos src={Intact} alt="Intact logo" />
        <Logos src={Unica} alt="Unica logo" />
        <Logos src={Berkley} alt="Berkley" />
        <Logos src={Coalition} alt="Coalition" /> 
    </Container>
    <Container>
        <Logos src={GA} alt="GA" />
        <Logos src={Cansure} alt="Cansure" /> 
        <Logos src={Bim} alt="BIM" />
        <Logos src={Premier} alt="Premier" />
        <Logos src={Eco} alt="Premier" />
    </Container>
    <Container>
    <Logos src={Evolution} alt="Evolution" />
    <Logos src={Abex} alt="Abex" />
        <Logos src={Chubb} alt="Chubb" />
        <LargeLogo src={MK_R_GG} alt="MK_R_GG" />
        <Logos src={Trinity} alt="Trinity" />
        <Logos src={Forward} alt="Forward" />
    </Container>
    </Section>
  )
}

export default PartnerLogos;